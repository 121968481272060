body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #f6f6f6 !important;
}
.full-screen {
    height: 100vh !important;
    max-width: 100vw !important;
}

.my-spinner-loader {
    width: 14rem !important;
    height: 14rem !important;
}

/* content and container */

.my-map-loader  {
    width: 100%;
    height: 50vh;
}
.card-container {
    overflow: hidden;
}

.card-img {
    max-width:100% !important;
}

.nice-zoom {
    transition: transform .2s;
}

.nice-zoom:hover {
    transform: scale3d(1.05, 1.05, 1);
    border: None;
}

.max-card  {
    max-height: 40rem;
}

.gallery-img {
    height: 18rem;
    width: 100%;
    object-fit: cover;
}

.decent-bg {
 background-color: rgba(0,0,0,.03) !important;
}

.decent-bg .card-footer {
    background-color: transparent;
}


@media screen and (min-width: 2200px) {
    .max-card {
        max-height: 24rem;
    }
    .gallery-img {
        height: 30rem;
    }
}

@media screen and (max-width: 1200px) and (min-width: 993px){
    .max-card {
        max-height: 30rem;
    }
}

@media screen and (max-width: 992px) and (min-width: 769px) {
    .max-card {
        max-height: 39rem;
    }
}

@media screen and (max-width: 768px) and (min-width: 578px) {
    .max-card {
        max-height: 28rem;
    }
    .gallery-img {
        height: 30rem;
    }
}

.strong-link{
    font-size: 2rem;
    color: white;
    font-weight: 400;
}
.spacer {
    height: 5vh;
}

.spacer-small {
    height: 2.5vh;
}

.text-container {
    width: 85vw !important;
}

@media screen and (min-width: 992px) {
    .text-container {
        width: 60vw !important;
    }
}

.tooltip {
    pointer-events: none;
    position: absolute;
    padding: 8px;
    background: white !important;
    color: #33333d  !important;
    min-width: 190px;
    max-height: 350px;
    opacity: 1 !important;
    visibility: visible !important;
}

.modal {
    display: flex !important;
    align-items: center;
}

.banner-text {
    position: absolute;
    top: 60%;
    left: 35%;
    transform: translate(-35%, -60%);
}
/* colors */
.ink-text {
    color: #33333d !important;
}

.huge-text {
    font-size: 1.4rem;
}

.dark-ink {
    background: #33333d
}

.avocado {
    color: #9ab260
}

.avocado-bg {
    background-color: #9ab260 !important;
}

.bone {
    color: #d4d4d4
}

.oak {
    color: #524a41
}

.fog {
    color: #7e847d
}
.weiden-gradient {
    background: linear-gradient(40deg, #f6f6f6, #9ab260) !important;
}


/* nav */
.navbar {
    min-height: 60px;
    opacity: 0.98;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.nav-item {
    font-size: 1.2rem !important;
    font-weight: 400 !important;
}

.nav-item .MuiButton-label {
    font-size: 1.2rem !important;
    font-weight: 400 !important;
}

.nav-item .MuiButton-label {
    font-size: 1.4rem;
    font-weight: 400 !important;
    text-transform: none !important;
    color: white;
}
.placeholder {
    height: 12rem !important;
    width: 100% !important;
}
.navbar .dropdown-menu a {
    font-size: 1.0rem;
}

.dropdown-toggle::after {
    content: none;
}

.nice-white-text {
    text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4),
    0px 8px 13px rgba(0, 0, 0, 0.1),
    0px 18px 23px rgba(0, 0, 0, 0.1);
}

.bigger-text {
    font-size: 2rem;
    font-weight: 400 !important;
}

.navbar.navbar-light .navbar-nav .nav-item .dropdown-menu .mega-nav {
    color: #33333d !important;
    background: #d4d4d4;
    font-size: 1.0rem;
}


@media (max-width: 991px) {
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: static !important;
    }
}


@media (min-width: 576px) and (max-width: 768px) {
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: static !important;
    }
}


.navbar .dropdown-menu a {
    font-size: 1.0rem !important;
}


.mycard-btn:hover {
    color: #33333d !important;
    font-weight: bold;
}

.small-banner {
    background-color: #33333d;
    background-size: cover;
    background-position: center;
    height: 30vh;
    width: 100%;
}

/* text */
.content-text-dark {
    color: #33333d !important;
}

.text-container {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.continuous-text {
    font-size: 1.0rem;
    line-height: 1.15;
}

.emphasised-text {
    font-weight: 400;
    font-size: 1.0rem;
    line-height: 1.15;
}

.my-card-text {
    font-weight: 400;
    color: #747373;
}

.content-wrapper {
    padding: 0;
    margin: auto;
    width: 100%;
}

.decent-bold {
    font-weight: 400 !important;
}

/* Footer */
.page-footer a {
    font-size: 1rem;
}
.link-footer {
    font-size: 0.8rem !important;
}

.link-footer:hover {
    font-weight: 800;
    text-decoration: underline;
}

.sticky-footer {
    margin-top: 3rem;
    position: relative;
    bottom: 0 !important;
    width: 100vw;
}

.copyright {
    font-size: 0.8rem !important;
}